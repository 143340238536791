import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { register } from '../../redux/actions/authActions';
import MetaTags from '../../components/MetaTags';
import { Link } from 'react-router-dom';
import logo from "../../assets/icons/login.png";

const RegisterPage = ({ someProp = 'default value' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [chekbox, setchekbox] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword ) {
    if ( chekbox === true ) {
      setIsLoading(true);
      dispatch(register({ fullName, username, whatsapp, email, password }, navigate, toast))
        .finally(() => setIsLoading(false));
    } else {
      toast.error(t('checkbox'));
    }
    } else {
      toast.error(t('Passwords do not match'));
    }
  };

  return (
    <>
      <MetaTags title={t('Register')} />
      <div className="flex min-h-screen bg-gray-400">
        {/* <div className="hidden md:flex w-1/2 bg-cover bg-center" style={{ backgroundImage: "url('../../assets/icons/Screenshot_2025-02-05_190510-removebg-preview.png')" }}>
        </div> */}
        <img   className="hidden md:flex w-1/2 bg-cover bg-center" src={logo} alt="logo" />
        <div className="flex flex-col justify-center items-center w-full md:w-1/2 bg-gray-500 p-6">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
            <h2 className="text-3xl font-bold mb-6 text-center">{t('Register')}</h2>
            <p className="mb-6 text-center text-gray-700">{t('joinOurEcommercePlatform')}</p>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="col-span-1">
                  <label className="block text-gray-700 mb-2">{t('full_name')}</label>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
                <div className="col-span-1">
                  <label className="block text-gray-700 mb-2">{t('Username')}</label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="col-span-1">
                  <label className="block text-gray-700 mb-2">{t('Whatsapp')}</label>
                  <PhoneInput
                  className=""
                    country={'us'} // default country
                    value={whatsapp}
                    onChange={setWhatsapp}
                    // inputClass="w-full px-3 py-2 border ms- rounded"
                    inputStyle={{ width: '100%' }}
                    containerClass="custom-phone-input"

                     inputClass="custom-phone-input-field"
                  />
                </div>
                <div className="col-span-1">
                  <label className="block text-gray-700 mb-2">{t('Email')}</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="col-span-1">
                  <label className="block text-gray-700 mb-2">{t('Password')}</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
                <div className="col-span-1">
                  <label className="block text-gray-700 mb-2">{t('Confirm Password')}</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
              </div>
            <div className='flex pb-4  gap-2 align-center '>
            <input className='mt-1' type='checkbox'   onClick={() => setchekbox(true)} /> 
            <a href="/ReturnPolicy">{t('policies')}</a>
            </div>  
              <button
                type="submit"
                className="w-1/2 mx-auto bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="loader w-5 h-5 border-t-2 border-white rounded-full animate-spin"></div>
                ) : (
                  t('Register')
                )}
              </button>
              <div className="text-center mt-4">
                <Link to="/login" className="text-blue-500 hover:underline ml-2">
                  {t('Login')}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default RegisterPage;
