import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import './css/Footer.css';
import logo from "../assets/icons/Screenshot_2025-02-05_190510-removebg-preview.png";
import Marquee from "react-fast-marquee";

function Footer() {
  const { t } = useTranslation();

  const importantLinks = [
    { key: 'AboutSitra', label: t('AboutSitra') },
    { key: 'FAQ', label: t('FAQ') },
    { key: 'BranchAddresses', label: t('BranchAddresses') },
    { key: 'ReturnPolicy', label: t('ReturnPolicy') },
    { key: 'ShippingPolicy', label: t('ShippingPolicy') },
    { key: 'Blog', label: t('Blog') },
  ];

  const socialMediaLinks = [
    // { icon: faFacebook, url: 'https://www.facebook.com/example' },
    // { icon: faTwitter, url: 'https://www.twitter.com/example' },
    { icon: faInstagram, url: 'https://www.instagram.com/bh.socks?igsh=aXd3d3h3Nmh6b2Yz&utm_source=qr' },
    // { icon: faLinkedin, url: 'https://www.linkedin.com/example' },
  ];

  const logoUrls = [
    'footer/1600828366fdedf14c91b84e675f838988a91f7ad0.webp',
    'footer/1610701410b3781f00695b77b833e6b6a5e38331a3.webp',
    '/footer/1651733282ff6b345534cfad09ad4f3f86c14d5b1a.webp',
    '/footer/1666059343f885bbe5200643cf6e5520edf4d48f44.webp',
    '/footer/1685342766a65f1c91843f5e3191183263e8aeca54.webp',
    '/footer/161528368123dd7a35ad8708b0dfc74b3630526891.webp',
    '/footer/169863276243bf4e90e9f85458890bed15c5a5aee7.webp',
    '/footer/15282719811871317559.webp',
    '/footer/1528273036537082707.webp',
    '/footer/15282731342688549608.webp',
    '/footer/1528273151799711689.webp',
    '/footer/15282732803587566708.webp',
    '/footer/15282732983375743706.webp',
    '/footer/15356946304173589516.webp',
    '/footer/162790376859463644223852a19dd980bbc128a587.webp',
    '/footer/image 83.png',
    '/footer/image 84.png',
    '/footer/DMCA-53fc2e1a50.png',
    '/footer/seal-43f93aaef6.png',
  ];

  const renderSocialMediaIcons = () => (
    <div className="flex justify-center mt-4">
      {socialMediaLinks.map((item, index) => (
        <a
          key={index}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white hover:text-blue-500 mx-4 transition duration-300"
        >
          <FontAwesomeIcon icon={item.icon} size="lg" />
        </a>
      ))}
    </div>
  );

  const renderLogos = () => (
<Marquee className="mt-4" speed={50} >
{logoUrls.map((url, index) => (
        <img key={index} src={url} alt={`Logo ${index + 1}`} className="h-10 mx-2" />
      ))}
    </Marquee>
  );

  return (
    <footer className="bg-gray-500 bg-opacity-75 py-8">
      <div className="container mx-auto px-4 text-center text-white">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mb-8">
        <div className=" sm:flex-row justify-center items-center mb-4">
          <div className="flex justify-start sm:justify-center w-full sm:w-auto">
            <img src={logo} alt="Logo" className="h-20 mr-4 mb-4 sm:mb-0" />
          </div>
          <div className="text-center flex flex-col items-center sm:flex-1">
            <p>&copy; 2024 {t('Home')}. All rights reserved.</p>
            {renderSocialMediaIcons()}
          </div>
        </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-left ">{t('Important_links')}</h3>
            <ul className='text-left'>
              {importantLinks.map((link) => (
                <li key={link.key}>
                  <a className="" href={`#${link.key.toLowerCase()}`} className="hover:text-blue-500 transition duration-300 text-white">
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-left">{t('Offers & Products')}</h3>
            <ul className='text-left'>
              <li><a href="#offers" className="hover:text-blue-500   transition duration-300 text-white ">{t('Offers')}</a></li>
              <li><a href="#new-products" className="hover:text-blue-500   transition duration-300 text-white">{t('New Products')}</a></li>
              <li><a href="#best-sellers" className="hover:text-blue-500   transition duration-300 text-white">{t('Best Sellers')}</a></li>
            </ul>
          </div>
      
          <div>
            <h3 className="text-xl font-bold mb-4 ">{t('Stay Connected')}</h3>
            <form className="flex flex-col sm:flex-row items-center justify-center">
              <div className="flex flex-col sm:flex-row w-full sm:w-auto">
                <input
                  type="email"
                  placeholder={t('Enter your email')}
                  className="p-2 border border-gray-300 rounded-l text-black flex-grow"
                />
                <button className="p-2 bg-black text-white rounded-r hover:bg-blue-600 transition duration-300">
                  {t('Subscribe')}
                </button>
              </div>
            </form>
          </div>
       
        </div>

      </div>
            {renderLogos()}
    </footer>
  );
}

export default Footer;
