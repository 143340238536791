// routes.js
import HomePage from '../pages/Home/HomePage';
import LoginPage from '../pages/auth/LoginPage';
import RegisterPage from '../pages/auth/RegisterPage';
import CategoryProducts  from '../pages/Category/CategoryProducts';
import FavoritesPage from '../pages/Favorites/FavoritesPage';
import CartPage from '../pages/Cart/CartPage';
import CheckoutPage from '../pages/Cart/CheckoutPage';
import ProductPage from '../pages/Product/ProductPage';
import ProductDetailsPage from '../pages/Product/ProductDetailsPage';
import ActivatePage from '../pages/auth/ActivatePage';
import OrdersPage from './../pages/Orders/OrdersPage';
import ProfilePage from '../pages/User/ProfilePage';
import ReturnPolicy from '../pages/Polices/ReturnPolicy';
import SuccessProcess from '../pages/payment/successprocess';
import FailureProcess from '../pages/payment/failureprocess';

const routes = [
  { path: '/', component: HomePage, exact: true, name: 'Home' },
  { path: '/products', component: ProductPage, name: 'Products' },
  { path: '/orders', component: OrdersPage, name: 'Orders', guarded: true },
  { path: '/favorites', component: FavoritesPage, guarded: true },
  { path: '/categories/:categoryId/subcategory/:subcategoryId', component: CategoryProducts },
  { path: '/cart', component: CartPage },
  { path: '/category/:categoryId/', component: CategoryProducts },
  { path: '/checkout', component: CheckoutPage },
  { path: '/product/:id', component: ProductDetailsPage },
  { path: '/login', component: LoginPage },
  { path: '/register', component: RegisterPage },
  { path: '/activate', component: ActivatePage },
  { path: '/profile', component: ProfilePage, guarded: true },
  { path: '/ReturnPolicy', component: ReturnPolicy, guarded: false },
  { path: '/SuccessProcess', component: SuccessProcess, guarded: false },
  { path: '/FailureProcess', component: FailureProcess, guarded: false },

];

export default routes;

// import CategoriesPage from '../pages/Category/CategoriesPage';
  // { path: '/categories', component: CategoriesPage },
