import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../redux/actions/categoryActions';
import { getFullImageUrl } from '../utils/imageUtils';
const borderColors = ['#000000', 'rgba(144, 158, 169, 1)', '#ff0000', '#0000ff', '#008000'];

const navStyles = {
  overflowX: 'auto',
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'none', 
  msOverflowStyle: 'none',
  padding: '0 1rem',
};

const ulStyles = {
  display: 'flex',
  flexWrap: 'nowrap',
  padding: '0',
  margin: '0',
  listStyle: 'none',
  gap: '1rem'
};

const TopBar = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories || []);
  const loading = useSelector((state) => state.category.loading);
  const error = useSelector((state) => state.category.error);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const parentCategories = categories.filter((category) => !category.parentId);

  return (
    <div className="TopBar bg-black text-white mt-3">
      <div className="mx-auto flex justify-between items-center ">
        <nav className="relative flex justify-start w-full" style={navStyles}>
          <ul className="flex space-x-4" style={ulStyles}>
            {parentCategories.map((category, index) => (
              <li key={category.id} className="relative group list-none flex-none">
                <Link to={`/category/${category.id}`} className="category-link flex flex-col items-center transform transition-transform hover:scale-105">
                  <div
                    className="m-2 w-20 h-20 md:w-40 md:h-40 flex items-center justify-center rounded-md"
                    // style={{
                    //   outline: `5px solid ${borderColors[index % borderColors.length]}`,
                    // }}
                  >
                    <div
                      className="text-center text-white w-20 h-20 md:w-40 md:h-40 flex items-center justify-center rounded-md relative"
                      style={{
                        background: `url(${category.image ? getFullImageUrl(category.image) : 'placeholder.jpg'}) center center / cover no-repeat`,
                        // border: '5px solid #FFFFFF',
                      }}
                    >
                      <span className="text-1xl md:text-2xl bg-black  bg-opacity-50 rounded-md p-2 overflow-hidden">
                        {i18n.language === 'ar' ? category.ar_name : category.en_name}
                      </span>
                      
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default TopBar;
