/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import doneImage from '../../assets/icons/pngegg.png'; 
import { useTranslation } from "react-i18next";

const EmailVerificationStud = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const [verificationStatus, setVerificationStatus] = useState('Verifying...');
    const [showPopup, setShowPopup] = useState(false); // State for popup visibility

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token_user');
        if (token) {
            verifyEmail(token);
        }
    }, [location.search]);

    // Function to toggle popup visibility
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
        }}>
            <div className='flex justify-center flex-col' style={{
                textAlign: 'center',
                padding: '20px',
                backgroundColor: '#ffffff',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                borderRadius: '10px',
                maxWidth: '80%',
                margin: 'auto',
            }}>
                <div className='w-full flex justify-center'> 
                    <img src={doneImage} alt="Done Icon" style={{ width: '150px', marginBottom: '20px' }} />
                </div>
                <h2>{t('failurepayment')}</h2>
                <Link to="/" style={{ textDecoration: 'none', marginTop: '20px' }}>
                    <button >
                       {t('trayagin')} 
                    </button>
                </Link>
                <button onClick={togglePopup} style={{
                    padding: '12px 24px',
                    backgroundColor: 'blue',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '30px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    margin: '10px 0',
                }}>
                    Show Popup
                </button>

                <Link to="/" style={{ textDecoration: 'none', marginTop: '20px' }}>
                    <button style={{
                        padding: '12px 24px',
                        backgroundColor: 'gray',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '30px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.2s ease-in-out',
                    }}>
                        {t('backtohome')}
                    </button>
                </Link>
            </div>

            {/* Popup Component */}
            {showPopup && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}>
                        <h3>Popup Title</h3>
                        <p>This is a simple popup message.</p>
                        <button onClick={togglePopup} style={{
                            padding: '10px 20px',
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            marginTop: '10px',
                        }}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmailVerificationStud;
