import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestimonials } from '../redux/actions/appActions';
import { getFullImageUrl, defaultAvatar } from "../utils/imageUtils";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './css/Testimonials.css';

const Testimonials = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const testimonials = useSelector((state) => state.app.testimonials) || [];
  const loading = useSelector((state) => state.app.loading);
  const error = useSelector((state) => state.app.error);

  useEffect(() => {
    dispatch(fetchTestimonials());
  }, [dispatch]);

  if (loading) {
    return <p>{t('loading_testimonials')}</p>;
  }

  if (error) {
    return <p>{t('error_loading_testimonials')} {error}</p>;
  }

  return (
    <div className="testimonials-container bg-gray-100 py-8 text-center">
      <h2 className="text-2xl font-bold mb-6">{t('client_testimonials')}</h2>
      <div className="testimonials-slider relative overflow-hidden">
        <div className="testimonials-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {testimonials.length > 0 ? (
            testimonials.map((testimonial) => (
              <div key={testimonial.id} className="testimonial-card py-2 shadow-sm text-center">
                <div className="flex justify-center mb-4">
                  <img 
                    src={testimonial.avatar ? getFullImageUrl(testimonial.avatar) : defaultAvatar}
                    alt={testimonial.username}
                    className="testimonial-avatar w-16 h-16 rounded-full"
                  />
                </div>
                <h3 className="text-xl font-semibold">{testimonial.username}</h3>
                <p className="testimonial-review text-gray-700 mt-2">
                  {testimonial.review.length > 100 ? `${testimonial.review.substring(0, 100)}...` : testimonial.review}
                </p>
                <div className="testimonial-rating text-yellow-500 mt-2">
                  {Array.from({ length: testimonial.rating }).map((_, index) => (
                    <span key={index}>&#9733;</span>
                  ))}
                </div>
                <div className="testimonial-date text-gray-500 text-sm mt-2">{testimonial.created_at}</div>
                <div className="testimonial-product mt-4">
                  <h4 className="text-lg font-semibold">{i18n.language === 'ar' ? testimonial.ar_product_title : testimonial.en_product_title}</h4>
                  <Link to={`/product/${testimonial.product_id}`} className="product-link inline-block mt-2 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600">
                    {t('view_product')}
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div className="testimonial-card p-4 text-center">
              <div className="flex justify-center mb-4">
                <img 
                  src={defaultAvatar}
                  alt="No testimonials"
                  className="testimonial-avatar w-16 h-16 rounded-full"
                />
              </div>
              <h3 className="text-xl font-semibold">{t('no_testimonials_found')}</h3>
              <p className="testimonial-review text-gray-700 mt-2">
                {t('no_testimonials_description')}
              </p>
              <div className="testimonial-rating text-yellow-500 mt-2">
                {Array.from({ length: 5 }).map((_, index) => (
                  <span key={index}>&#9733;</span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
