import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategoriesWithChildren } from '../redux/actions/categoryActions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getCurrentLanguage } from '../utils/languageUtils';

const CategoriesMenu = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);
  const loading = useSelector((state) => state.category.loading);
  const error = useSelector((state) => state.category.error);
  const { i18n } = useTranslation();

  const [openCategory, setOpenCategory] = useState(null);
  const [openSubcategory, setOpenSubcategory] = useState({});
  const currentLanguage = getCurrentLanguage();
  const menuRef = useRef(null);

  useEffect(() => {
    dispatch(fetchCategoriesWithChildren());
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenCategory(null);
        setOpenSubcategory({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleCategory = (categoryId) => {
    setOpenCategory((prevOpenCategory) =>
      prevOpenCategory === categoryId ? null : categoryId
    );
  };

  const toggleSubcategory = (categoryId, subcategoryId) => {
    setOpenSubcategory((prevOpenSubcategory) => ({
      ...prevOpenSubcategory,
      [subcategoryId]: !prevOpenSubcategory[subcategoryId],
    }));
  };

  const renderChildren = (categoryId, children) => {
    if (!Array.isArray(children) || children.length === 0) return null;

    return (
      <div className="ml-4 lg:ml-0 lg:absolute lg:top-0 lg:left-full lg:mt-2 bg-light p-3 rounded shadow-sm z-10">
        {children.map((child) => (
          <div key={child.id} className="p-2 relative">
            <div className="d-flex justify-content-between align-items-center">
              <Link
                to={`/categories/${categoryId}/subcategory/${child.id}`}
                className="block p-2 bg-gray-100 hover:bg-gray-200 text-black shadow-md rounded-lg flex-grow-1"
              >
                {i18n.language === 'ar' ? child.ar_name : child.en_name}
              </Link>
              {Array.isArray(child.children) && child.children.length > 0 && (
                <button
                  onClick={() => toggleSubcategory(categoryId, child.id)}
                  className="ml-2 bg-transparent border-none"
                >
                  <FontAwesomeIcon
                    icon={openSubcategory[child.id] ? faChevronDown : faChevronRight}
                  />
                </button>
              )}
            </div>
            {Array.isArray(child.children) &&
              child.children.length > 0 &&
              openSubcategory[child.id] &&
              <div className="lg:absolute lg:top-0 lg:left-full lg:mt-2 bg-light p-3 rounded shadow-sm z-10">
                {renderChildren(categoryId, child.children)}
              </div>
              }
          </div>
        ))}
      </div>
    );
  };

  const renderSubcategories = (categoryId, subcategories) => {
    if (!Array.isArray(subcategories) || subcategories.length === 0) return null;

    return (
      <div className={`lg:absolute  ${currentLanguage === 'ar' ? 'lg:right-' : 'lg:left-'} lg:top-10 mt-2 w-full z-10 ${openCategory === null ? 'hidden' : 'block'}`}>
        <div className="bg-light p-3 rounded shadow-sm">
          {subcategories.map((subcategory) => (
            <div key={subcategory.id} className="p-2 relative">
              <div className="d-flex justify-content-between align-items-center">
                <Link
                  to={`/categories/${categoryId}/subcategory/${subcategory.id}`}
                  className="block p-2 bg-gray-100 text-black shadow-md hover:bg-gray-200 rounded-lg flex-grow-1"
                >
                  {i18n.language === 'ar' ? subcategory.ar_name : subcategory.en_name}
                </Link>
                {Array.isArray(subcategory.children) && subcategory.children.length > 0 && (
                  <button
                    onClick={() => toggleSubcategory(categoryId, subcategory.id)}
                    className="ml-2 bg-transparent border-none"
                  >
                    <FontAwesomeIcon
                    />
                                      <FontAwesomeIcon className={` ${currentLanguage === 'ar' ? 'rotate-180' : 'rotate-0'}`}                       icon={openSubcategory[subcategory.id] ? faChevronDown : faChevronRight}
 />

                  </button>
                )}
              </div>
              {Array.isArray(subcategory.children) &&
                subcategory.children.length > 0 &&
                openSubcategory[subcategory.id] &&
                <div className={`lg:absolute lg:top-0 ${currentLanguage === 'ar' ? 'lg:right-[160%]' : 'lg:left-full'} lg:mt-2 bg-light p-3 rounded shadow-sm z-10`}>
                  {renderChildren(categoryId, subcategory.children)}
                </div>}
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    
    <div className="px-5" ref={menuRef}>
      <nav className="categories-menu">
        <ul className="grid grid-cols-2 md:flex flex-wrap list-none p-0 m-0">
          {categories.map((category) => (
            <li key={category.id} className="relative category-item-wrapper text-black p-2">
              <div
                className="cursor-pointer px-2 py-2 bg-cover bg-center text-black rounded-lg shadow-lg flex justify-content-between align-items-center w-full hover:bg-gray-200"
                onClick={() => toggleCategory(category.id)}
              >
                <div className="category-name w-48 truncate">
                  {i18n.language === 'ar' ? category.ar_name : category.en_name}
                </div>
            <div className='ps-2'>
            {Array.isArray(category.subcategories) && category.subcategories.length > 0 && (
                  <FontAwesomeIcon className={` ${currentLanguage === 'ar' ? 'rotate-180' : 'rotate-0'}`} icon={openCategory === category.id ? faChevronDown : faChevronRight} />
                )}
            </div>
              </div>
             <div className=''>
             {openCategory === category.id &&
                Array.isArray(category.subcategories) &&
                category.subcategories.length > 0 &&
                renderSubcategories(category.id, category.subcategories)}
             </div>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default CategoriesMenu;
