import React, { useState } from "react";
// import returnPolicy from "./returnPolicyData"; // Import the key-value object
import { useTranslation } from 'react-i18next';

const ReturnPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4 text-center">{t('title')}</h1>
      <p className="mb-4">{t('intro')}</p>

      {/* Policy Sections */}
      {['returns', 'refunds', 'shipping', 'contact'].map((section) => (
        <div key={section} >
          <h2 className="text-2xl font-semibold mt-6 mb-2">{t(`${section}_title`)}</h2>
          <p className="mb-4">{t(`${section}_description`)}</p>
        </div>
      ))}
    
    </div>
  );
};

export default ReturnPolicy;
