import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSectionProducts } from '../../redux/actions/productActions';
import TopBar from '../../components/TopBar';
import Slider from '../../components/Slider';
import SecondSlide from '../../components/SecondSlide';
import { useTranslation } from 'react-i18next';
import EcommerceCard from '../../components/EcommerceCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import AdPopup from '../../components/AdPopup';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';
import CategoriesMenu from '../../components/CategoriesMenu';
import MetaTags from '../../components/MetaTags';
import Testimonials from '../../components/Testimonials';
import { Link } from 'react-router-dom';

function HomePage() {
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.products.sections) || [];
  const loading = useSelector((state) => state.products.loading);
  const error = useSelector((state) => state.products.error);

  const { i18n, t } = useTranslation();

  const [isAdVisible, setIsAdVisible] = useState(false);

  useEffect(() => {
    const adShown = sessionStorage.getItem('adShown');
    if (!adShown) {
      setIsAdVisible(true);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSectionProducts());
  }, [dispatch]);

  const handleCloseAd = () => {
    sessionStorage.setItem('adShown', 'true');
    setIsAdVisible(false);
  };

  const scrollLeft = (id) => {
    document.getElementById(id).scrollLeft -= 200;
  };

  const scrollRight = (id) => {
    document.getElementById(id).scrollLeft += 200;
  };

  const renderSection = (sectionObject) => {
    const { section, products, category_ar_name, category_en_name, category_id } = sectionObject;
    const productsArray = Object.values(products);

    let sectionTitle = t(section); 

    if (section === 'random_category') {
      sectionTitle = i18n.language === 'ar' ? category_ar_name : category_en_name;
    }

    const linkPath = section === 'random_category' 
      ? `/category/${category_id}/` 
      : `/products?section=${section}`;

    return (
      <div className="home-section mb-4 text-center" key={section}>
        <Link to={linkPath} title={i18n.language === 'ar' ? `عرض المزيد من ${sectionTitle}` : `Show all ${sectionTitle}`}>
          <h2 className="home-section-title text-5xl font-bold mb-4 text-black">{sectionTitle}</h2>
        </Link>


        <div className="home-section-content position-relative d-flex align-items-center">
         
          <div id={section} className="home-cards-container  d-flex overflow-auto scroll-smooth w-100">
            {productsArray.length > 0 ? (
              productsArray.map((product, index) => (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-2 d-flex justify-content-center" key={index}>
                  <EcommerceCard
                    productId={product.id}
                    images={product.images}
                    title={i18n.language === 'ar' ? product.ar_title : product.en_title}
                    price={parseFloat(product.price)}
                    description={i18n.language === 'ar' ? product.ar_description : product.en_description}
                    discount={product.discount ? product.discount : 0}
                    discountType={product.discount_type}
                    discountedPrice={parseFloat(product.discounted_price)}
                    tagText={product.tag_text}
                    isNew={product.is_new}
                    tagColor={product.tag_color}
                    currency={product.currency}
                    favorite={product.favorite}
                    cart={product.cart}
                    stock={product.stock}
                    className="w-100 "
                  />
                </div>
              ))
            ) : (
              <p>No products available</p>
            )}
          </div>
         
        </div>
      </div>
    );
  };

  return (
    <>
      <MetaTags title={t('Home')} />
      <div className="home-page">
        <div className="flex justify-center">
          <CategoriesMenu />
        </div>
        <Slider />
        <TopBar />
        <div className="home-container mt-4 text-center">
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {sections.map(renderSection)}
          <SecondSlide />
          <Testimonials />
        </div>
        <AdPopup isVisible={isAdVisible} onClose={handleCloseAd} />
      </div>
    </>
  );
}

export default HomePage;
