import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import routes from './routes/route';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AuthGuard from './components/AuthGuard';
import RedirectIfAuthenticated from './components/RedirectIfAuthenticated';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdsNavBar from './components/AdsNavBar';

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (!['/login', '/register', '/activate'].includes(location.pathname)) {
      Cookies.set('lastRoute', location.pathname);
    }
  }, [location]);

  return (
    <>
      <AdsNavBar />
      <div className="flex flex-col min-h-screen w-full">
        <Navbar />
        <main className="flex-grow w-full pt-36 md:pt-16">
          <Routes>
            {routes.map((route, index) => (
              route.guarded ? (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <AuthGuard>
                      <route.component />
                    </AuthGuard>
                  }
                />
              ) : (
                route.path === '/login' || route.path === '/register' ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <RedirectIfAuthenticated>
                        <route.component />
                      </RedirectIfAuthenticated>
                    }
                  />
                ) : (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                )
              )
            ))}
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
