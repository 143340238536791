import {
  getUserOrders,
  createOrder,
  viewOrder,
  deleteOrder,
  returnOrderService,
  fetchReturnOrderDetailsService
} from '../../services/orderService';
import { clearCartItems } from './cartActions';
import { calculateCartTotalAction } from './appActions';

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const VIEW_ORDER_REQUEST = 'VIEW_ORDER_REQUEST';
export const VIEW_ORDER_SUCCESS = 'VIEW_ORDER_SUCCESS';
export const VIEW_ORDER_FAILURE = 'VIEW_ORDER_FAILURE';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';


export const RETURN_ORDER_REQUEST = 'RETURN_ORDER_REQUEST';
export const RETURN_ORDER_SUCCESS = 'RETURN_ORDER_SUCCESS';
export const RETURN_ORDER_FAILURE = 'RETURN_ORDER_FAILURE';

export const FETCH_RETURN_ORDER_DETAILS_REQUEST = 'FETCH_RETURN_ORDER_DETAILS_REQUEST';
export const FETCH_RETURN_ORDER_DETAILS_SUCCESS = 'FETCH_RETURN_ORDER_DETAILS_SUCCESS';
export const FETCH_RETURN_ORDER_DETAILS_FAILURE = 'FETCH_RETURN_ORDER_DETAILS_FAILURE';


export const fetchUserOrders = (currency, page = 1, perPage = 10) => async (dispatch) => {
  dispatch({ type: GET_ORDERS_REQUEST });
  try {
    const data = await getUserOrders(currency, page, perPage);
    dispatch({ type: GET_ORDERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ORDERS_FAILURE, payload: error.response?.data || error.message });
  }
};

export const createNewOrder = (userId, cartId, currency, address, description, discountCodeId) => async (dispatch, getState) => {
  dispatch({ type: CREATE_ORDER_REQUEST });
  try {
    const data = await createOrder(userId, cartId, currency, address, description, discountCodeId);
    dispatch({ type: CREATE_ORDER_SUCCESS, payload: data });
    dispatch(clearCartItems()); // Clear cart items after creating an order
    
    const { auth } = getState();
    dispatch(calculateCartTotalAction(currency, auth.cookieId)); // Update cart total after clearing cart items

    return data; // Return data to allow handling in the component
  } catch (error) {
    const errorMessage = error.response?.data || error.message;
    dispatch({ type: CREATE_ORDER_FAILURE, payload: errorMessage });
    throw errorMessage; // Throw error to handle it in the component
  }
};

export const fetchOrder = (orderId, currency) => async (dispatch) => {
  dispatch({ type: VIEW_ORDER_REQUEST });
  try {
    const data = await viewOrder(orderId, currency);
    dispatch({ type: VIEW_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: VIEW_ORDER_FAILURE, payload: error.response?.data || error.message });
  }
};

export const removeOrder = (orderId, currency, page, perPage) => async (dispatch) => {
  dispatch({ type: DELETE_ORDER_REQUEST });
  try {
    await deleteOrder(orderId);
    dispatch({ type: DELETE_ORDER_SUCCESS, payload: orderId });
    dispatch(fetchUserOrders(currency, page, perPage)); 
  } catch (error) {
    dispatch({ type: DELETE_ORDER_FAILURE, payload: error.response?.data || error.message });
  }
};


export const returnOrder = (orderId, reason) => async (dispatch) => {
  dispatch({ type: RETURN_ORDER_REQUEST });
  try {
    const data = await returnOrderService(orderId, reason);
    dispatch({ type: RETURN_ORDER_SUCCESS, payload: data });
    dispatch(fetchUserOrders()); // Optionally refetch orders
  } catch (error) {
    dispatch({ type: RETURN_ORDER_FAILURE, payload: error.response?.data || error.message });
  }
};

export const fetchReturnOrderDetails = (orderId) => async (dispatch) => {
  dispatch({ type: FETCH_RETURN_ORDER_DETAILS_REQUEST });
  try {
    const data = await fetchReturnOrderDetailsService(orderId);
    console.log(data);
    dispatch({ type: FETCH_RETURN_ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_RETURN_ORDER_DETAILS_FAILURE, payload: error.response?.data || error.message });
  }
};