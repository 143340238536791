import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSlides } from '../redux/actions/appActions';
import { getFullImageUrl } from '../utils/imageUtils';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/Slideshow.css';
import { getCurrentLanguage } from '../utils/languageUtils';
import { Link } from 'react-router-dom';

const Slideshow = () => {
  const dispatch = useDispatch();
  const sliderRef = useRef(null);
  const slides = useSelector((state) => state.app.slides);
  const loading = useSelector((state) => state.app.loading);
  const error = useSelector((state) => state.app.error);

  useEffect(() => {
    dispatch(fetchSlides());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const slides = document.querySelectorAll('.slide');
      slides.forEach(slide => {
        const rect = slide.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isVisible) {
          slide.querySelector('.slide-image').classList.add('zoomed');
        } else {
          slide.querySelector('.slide-image').classList.remove('zoomed');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (loading) {
    return <p>Loading ...</p>;
  }

  if (error) {
    return <p>Error loading slides: {error}</p>;
  }

  if (slides.length === 0) {
    return <p></p>;
  }

  const currentLanguage = getCurrentLanguage();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleClick = (e) => {
    const slideWidth = e.currentTarget.offsetWidth;
    const clickPosition = e.clientX - e.currentTarget.getBoundingClientRect().left;

    if (clickPosition > slideWidth * 0.75) {
      sliderRef.current.slickNext();
    } else if (clickPosition < slideWidth * 0.25) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="slideshow-container">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide" onClick={handleClick}>
            <div
            >
              <img className="slide-image"
                src={getFullImageUrl(slide.image)} alt="" />
              <div
                className="description-overlay"
                style={{
                  left: currentLanguage === 'ar' ? 'unset' : '5%',
                  right: currentLanguage === 'ar' ? '5%' : 'unset',
                  textAlign: currentLanguage === 'ar' ? 'start' : 'unset',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                {/* text */}
                <div className="text-content">
                  <div className={`text-3xl ${currentLanguage === 'ar' ? 'text-end' : ''}`} style={{ background: 'rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '5px' }}>
                    {currentLanguage === 'ar' ? slide.ar_description : slide.en_description}
                  </div>

                 <div className={`w-full flex ${currentLanguage === 'ar' ? 'justify-end' : ''}`} >
                 {slide.link && (
                    <Link to={slide.link} className="second-slide_link">
                      {currentLanguage === 'ar' ? 'عرض المزيد' : 'Read more'}
                    </Link>
                  )}
                 </div>
                </div>

              </div>
              <div className="button-content mt-4 ">
                <button
                  className="bg-gray-500 hover:bg-black-900 text-white font-bold py-2 px-4 rounded-full"
                  onClick={() => window.location.href = slide.link}
                >
                  {currentLanguage === 'ar' ? 'عرض المزيد' : 'Read more'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slideshow;
